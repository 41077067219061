'process i18n';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import gettext from 'airborne/gettext';
import classnames from 'classnames';
import pad from 'lodash/pad';
import truncate from 'lodash/truncate';

import {Link} from 'react-router-dom';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import ActionableCounter from 'airborne/common/ActionableCounter';

import Permission from './Permission';
import Langselector from './Langselector';
import UATWarning from './UATWarning';
import ProviderTestEnvsWarning from './ProviderTestEnvsWarning';
import {roleName} from '../data/UserFilter';
import Inbox from 'airborne/header2/Inbox';
import location from 'airborne/location';
import {showSettings} from 'airborne/store/modules/header/actions/changeStep';
import settings from 'airborne/settings';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import {Permission as GranularPermission} from 'midoffice/helpers/permission';
import {sendLogout} from 'airborne/store/modules/logout/actions/logout';
import AnnotateTooltip from 'airborne/search2/hotel/AnnotateTooltip';

import history from 'airborne/browserHistory';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';
import Shortcut from 'midoffice/components/shortcut/Shortcut';
import systemData from 'airborne/systemData';

const MAX_CHARS_OF_USER_GROUP = 25;
const USER_GROUP_DIVIDER = '|';
const USER_GROUP_DIVIDER_LENGHT = 3;
const USER_GROUP_ENDING = '…';

const SUPPORT_LINK = 'https://getgoing1.zendesk.com/hc/en-us';
const getNabooLink = () => settings.ENDPOINTS.naboo || '';
const getFasttraceLink = () => settings.ENDPOINTS.fasttrace || '';
const getYakovlevLink = () => settings.ENDPOINTS.yakovlev || '';

const USERS_MENU_NEEDS = [
    'midoffice:groups:user_without_group:view',
    'midoffice:groups:agent:view',
    'midoffice:groups:account_manager:view',
    'midoffice:groups:admin:view',
    'midoffice:groups:advertising_manager:view',
    'midoffice:groups:booking_admin:view',
    'midoffice:groups:revenue_manager:view',
    'midoffice:groups:pnr_admin:view',
    'midoffice:groups:site_admin:view',
    'midoffice:groups:supplier_relations:view',
    'midoffice:api_keys:view',
];

export const getMenu = () => {
    const MENU = [
        {id: 'users', label: 'Users', permission: 'section:user', need: USERS_MENU_NEEDS, shortcut: SHORTCUTS_LIST.S},
        {id: 'companies', label: 'Companies', permission: 'section:company', shortcut: SHORTCUTS_LIST.C},
        {id: 'agencies', label: 'Agencies', permission: 'section:branch', shortcut: SHORTCUTS_LIST.A},
        {
            id: 'bookings', label: 'Booking Records', permission: 'aft:booking_records:edit', shortcut: SHORTCUTS_LIST.B,
            external: `${systemData.common.AFT_URL}/bookings/hotels/`,
        },
        {id: 'tools', label: 'Tools', nested: [
            {id: 'fasttrace', label: 'Fasttrace', permission: 'section:fasttrace', shortcut: SHORTCUTS_LIST.E, external: getFasttraceLink},
            {id: 'gds-console', label: 'QA Tools', permission: 'section:gds-console', external: getYakovlevLink},
            {id: 'pnr-tool', label: 'PNR Tools', permission: 'section:pnr', shortcut: SHORTCUTS_LIST.T},
            {id: 'aft-messaging', label: 'AFT Messaging', permission: 'section:aft-messaging', shortcut: SHORTCUTS_LIST.M},
            {id: 'flags', label: 'Flags', permission: 'section:flags', shortcut: SHORTCUTS_LIST.F},
            {id: 'naboo', label: 'Naboo', permission: 'section:naboo', external: getNabooLink},
            {id: 'labeling', label: 'Bed/Room labeling', permission: 'section:description-labeling', shortcut: SHORTCUTS_LIST.L},
            {id: 'provider-credentials', label: 'Provider Сredentials', permission: 'tools:gds_auth_details'},
        ]},
        {id: 'audit-logs', label: 'Audit Logs', permission: 'section:logs', shortcut: SHORTCUTS_LIST.O},
    ];
    return MENU;
};


@withRouter
class MenuLink extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        shortcut: PropTypes.string,
        disabled: PropTypes.bool,
        permission: PropTypes.string.isRequired,
        external: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
        ]),
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
        onSelect: PropTypes.func,
        activeShortcut: PropTypes.bool,
    };

    getLink = () => {
        const {id, external} = this.props;
        if (external) {
            return typeof external === 'function' ? external() : external;
        }

        return `/${id}/`;
    }

    handleLinkShortcut = () => {
        this.props.external ? window.open(this.getLink()) : history.push(this.getLink());
    }

    renderLink() {
        const {external, label, shortcut} = this.props;

        const linkLabel = <Shortcut label={label} shortcut={shortcut} action={this.handleLinkShortcut} />;
        if (external) {
            const externalLink =
                typeof external === 'function' ? external() : external;
            return <a href={externalLink} target="_blank">{linkLabel}</a>;
        }

        return <Link to={this.getLink()}>{linkLabel}</Link>;
    }

    render() {
        const {id, permission, disabled, location} = this.props;
        const cls = classnames({
            active: location.pathname.startsWith(`/${id}/`) || id === 'users' && location.pathname.startsWith('/ui/'),
        });

        if (disabled) { return null; }
        return (
            <Permission need={permission} key={id}>
                <li onClick={this.props.onSelect} data-section={id} className={cls}>
                    {this.renderLink()}
                </li>
            </Permission>
        );

    }
}

@withRouter
class NestedMenu extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        permission: PropTypes.string,
        nested: PropTypes.array.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired,
        isOptionKeyActive: PropTypes.bool,
    };

    render() {
        const {id, permission, label, nested, location} = this.props;
        const cls = classnames({
            active: location.pathname.startsWith(`/${id}`),
        });

        return (<Permission key={id} need={permission} nested={nested}>
            <li className={cls} >
                <DropdownButton className="dropdown" bsStyle="link" title={label} id={id} >
                    {nested.map((sub)=> (
                        <MenuLink
                            key={sub.id}
                            bsRole="menu"
                            {...sub}
                            shortcut={sub.shortcut}
                        />
                    ))}
                </DropdownButton>
            </li>
        </Permission>);
    }
}

function UserGroups({groups}) {
    if (!groups || groups.length === 0) {
        return 'No groups';
    }

    const fullString = groups.join(pad(USER_GROUP_DIVIDER, USER_GROUP_DIVIDER_LENGHT));

    const shortString = truncate(fullString, {
        length: MAX_CHARS_OF_USER_GROUP,
        separator: /((\s\|\s)|(?<!\|)\s(?!\|))/,
        omission: USER_GROUP_ENDING,
    });

    return shortString === fullString
        ? fullString
        : (<AnnotateTooltip
            id="user_groups"
            placement="left"
        >
            {shortString}
            {fullString}
        </AnnotateTooltip>);
}

UserGroups.propTypes = {
    groups: PropTypes.arrayOf(PropTypes.string),
};

@connect((store) => ({
    isTestLanguageFlag: getFeatureFlag(store, 'QA_TEST_LANGUAGE'),
}), {
    onOpenSettings: showSettings,
    logout: sendLogout,
})
export default class ApplicationHeader extends React.Component {

    static propTypes = {
        mainMenu: PropTypes.bool.isRequired,
        showLang: PropTypes.bool.isRequired,
        user: PropTypes.object.isRequired,
        languages: PropTypes.array.isRequired,
        selectedLanguage: PropTypes.string.isRequired,
        isTestLanguageFlag: PropTypes.bool.isRequired,
        isReportingEnabled: PropTypes.bool,

        // from mapDispatchToProps
        onOpenSettings: PropTypes.func.isRequired,
        logout: PropTypes.func,
    };

    shortcuts = {
        logout: SHORTCUTS_LIST.LOGOUT,
        settings: SHORTCUTS_LIST.SETTINGS,
    };

    handleNewSettings = () => {
        const {onOpenSettings} = this.props;
        settings.SUB_PARTNER !== 'common' ? onOpenSettings() : location.assign('/profile-settings/');
    }

    handleLogout = () => {
        this.props.logout();
    }

    renderAftMenu() {
        return (<ul className="user-nav">
            <li>
                <Link to="/bookings/"><ActionableCounter>{gettext('Booking Records')}</ActionableCounter></Link>
            </li>
        </ul>);
    }

    renderItem = (item) => {
        return (<GranularPermission need={item.need} key={item.id} >{item.nested
            ? (<NestedMenu key={item.id} {...item} />)
            : (<MenuLink key={item.id} shortcut={item.shortcut} bsRole="menu" {...item}/>)
        }</GranularPermission>);
    }

    renderMainMenu() {
        return (<ul className="main-nav">
            {getMenu().map(this.renderItem)}
        </ul>);
    }

    renderName() {
        const {first_name: first, last_name: last, role, groups} = this.props.user; // eslint-disable-line camelcase
        const name = [first, last].join(' ');

        const rolename = roleName(role) || <UserGroups groups={groups} />;

        return (<span className="header__dropdown-nav__wrapper">
            {name}
            {rolename && <span
                className="header__dropdown-nav__sub">{rolename}</span>}
        </span>);
    }

    render() {
        const {languages, selectedLanguage, mainMenu, showLang, isTestLanguageFlag, logout} = this.props;
        const logoClass = classnames('logo', {
            'c--logo': mainMenu,
            'logo--ag': !mainMenu,
        });
        return (
            <>
                <div className="header">
                    <div className="container-fluid">
                        <a href="/" className={logoClass} />
                        {mainMenu ? this.renderMainMenu() : this.renderAftMenu()}

                        <ul className="header__dropdown-nav">
                            <DropdownButton className="dropdown" bsStyle="link" title={this.renderName()} pullRight id="user-menu" >
                                <MenuItem onClick={this.handleNewSettings}>
                                    <Shortcut label={gettext('My Settings')} shortcut={this.shortcuts.settings} action={this.handleNewSettings} />
                                </MenuItem>
                                <MenuItem href={SUPPORT_LINK} eventKey={3.2} target="_blank">{gettext('Support')}</MenuItem>
                                <MenuItem divider />
                                <MenuItem onClick={this.handleLogout} shortcut={this.shortcuts.logout}>
                                    <Shortcut label={gettext('Logout')} shortcut={this.shortcuts.logout} action={logout} />
                                </MenuItem>
                            </DropdownButton>
                            {showLang && <Langselector isTestLanguageFlag={isTestLanguageFlag} selectedLanguage={selectedLanguage} languages={languages} />}
                        </ul>
                        <Inbox />
                    </div>
                </div>
                <UATWarning />
                <ProviderTestEnvsWarning />
            </>
        );
    }
}
