import React from 'react';
import PropTypes from 'prop-types';
import api from 'midoffice/helpers/api';
import classnames from 'classnames';
import gettext from 'airborne/gettext';
import location from 'airborne/location';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import {TEST_LANGUAGE} from 'midoffice/helpers/languages';

class Lang extends React.Component {
    static propTypes = {
        code: PropTypes.string,
        label: PropTypes.string,
        selected: PropTypes.bool,
        isTestLanguageFlag: PropTypes.bool,
        onSelect: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(event) {
        event.preventDefault();
        this.props.onSelect(this.props.code);
    }

    render() {
        const {code, label, selected, isTestLanguageFlag} = this.props;
        const cls = classnames({selected});
        if (code === TEST_LANGUAGE && !isTestLanguageFlag) {
            return null;
        }
        return (<li>
            <a href="#" onClick={this.handleSelect} className={cls}>
                <i className={`icon-flag-${code}`} />
                {label}
            </a>
        </li>);
    }
}

export default class Langselector extends React.Component {
    static propTypes = {
        languages: PropTypes.array.isRequired,
        isTestLanguageFlag: PropTypes.bool.isRequired,
        selectedLanguage: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(code) {
        const url = `/i18n/change-language?session_only=true&lang_code=${code}`;
        api('GET', url).then(()=> location.reload());
    }

    renderLang([code, label]) {
        const {selectedLanguage, isTestLanguageFlag} = this.props;
        return (<Lang key={code} isTestLanguageFlag={isTestLanguageFlag} code={code} label={label} selected={selectedLanguage === code} onSelect={this.handleSelect} />);
    }

    render() {
        const {selectedLanguage, languages} = this.props;
        return (<DropdownButton id="lang" className="dropdown" bsStyle="link" title={<i className={`icon-flag-${selectedLanguage}`} />} pullRight >
            <span className="drop-down-header">{gettext('Choose a language')}</span>
            <ul className="language">
                {languages.map((lang)=> this.renderLang(lang))}
            </ul>
        </DropdownButton>);
    }
}
